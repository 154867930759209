import { AdSlot } from 'components/UI/AdSlot';
import { type AdsProps } from 'types/ads';

export type WidgetAdSlotProps = {
  readonly model: {
    readonly fields: AdsProps;
  };
};

const WidgetAdslotWeb = ({ context }: { context: WidgetAdSlotProps }) => {
  const { name, position, viewport, outbrainWidgetId, skipPrediction } = context.model.fields;

  return (
    <AdSlot
      name={name}
      position={position}
      viewport={viewport}
      outbrainWidgetId={outbrainWidgetId}
      skipPrediction={skipPrediction}
    />
  );
};

export default WidgetAdslotWeb;
